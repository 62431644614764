import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ServicesLayout from "../../components/services-layout"
import BookButton from "../../components/book-button"

const ImageContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  justify-items: stretch;
`

export default () => {
  const data = useStaticQuery(graphql`
    fragment cornsAndCallusesImages on File {
      childImageSharp {
        fluid(maxHeight: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    query {
      a: file(relativePath: { eq: "services/calluses-before-after.png" }) {
        ...cornsAndCallusesImages
      }
      b: file(relativePath: { eq: "services/dry-feet-skin-before-after.png" }) {
        ...cornsAndCallusesImages
      }
      c: file(relativePath: { eq: "services/cracked-heels-before-after.jpg" }) {
        ...cornsAndCallusesImages
      }
      d: file(
        relativePath: { eq: "services/cracked-heels-before-after-2.jpg" }
      ) {
        ...cornsAndCallusesImages
      }
      e: file(
        relativePath: { eq: "services/cracked-heels-before-after-3.png" }
      ) {
        ...cornsAndCallusesImages
      }
      f: file(relativePath: { eq: "services/corn-treated.png" }) {
        ...cornsAndCallusesImages
      }
      g: file(relativePath: { eq: "services/corn-removed.png" }) {
        ...cornsAndCallusesImages
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Corns, calluses and hard skin removal"/>
      <ServicesLayout title="Corns, calluses and hard skin removal">
        <h2>What are calluses?</h2>
        <p>
          Calluses are the accumulation of dead skin cells that harden and
          thicken over an area of the foot. It is the body's defence mechanism
          for protection against excessive pressure and friction. However, when
          the callus becomes too thick, it can cause pain and crack.
        </p>
        <h2>What are corns?</h2>
        <p>
          Corns are like calluses and develop from an accumulation of dead skin
          cells at a point on the foot, forming think, hardened spots. They are
          internally cone-shaped with a point that can press on a nerve, causing
          pain. Corns are a very common ailment and feel like a pebble in your
          shoe.
        </p>
        <h2>How do you treat corns and calluses</h2>
        <p>
          The treatment is the same for both corns and calluses. Your podiatrist
          can painlessly remove both of these for you.
        </p>
        <h2>Pictures before and after treatment*</h2>
        <h3>Dry skin and calluses:</h3>
        <ImageContainer>
          <div>
            <Img
              fluid={data.a.childImageSharp.fluid}
              alt="Callus and dry skin treatment example"
            />
          </div>
          <div>
            <Img
              fluid={data.b.childImageSharp.fluid}
              alt="Before and after treatment of severed dry skin"
            />
          </div>
          <div>
            <Img
              fluid={data.c.childImageSharp.fluid}
              alt="Cracked and dry heels treatment"
            />
          </div>
          <div>
            <Img
              fluid={data.d.childImageSharp.fluid}
              alt="Smootherning and treating cracked heels"
            />
          </div>
          <div>
            <Img
              fluid={data.e.childImageSharp.fluid}
              alt="Before and after cracked heels treatment"
            />
          </div>
        </ImageContainer>
        <h3>Corns:</h3>
        <ImageContainer>
          <div>
            <Img
              fluid={data.f.childImageSharp.fluid}
              alt="Corn removal example 2"
            />
          </div>
          <div>
            <Img
              fluid={data.g.childImageSharp.fluid}
              alt="Corn removal example 2"
            />
          </div>
        </ImageContainer>
        <p>
          *{" "}
          <i>
            All pictures are anonymised and published here with their owner's
            permission
          </i>
        </p>
        <BookButton text="Remove my corn/callus now" size="1.5rem" />
      </ServicesLayout>
    </Layout>
  )
}
